import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { Location } from '@angular/common';

import { Title } from '@angular/platform-browser';
import { CoreNavigationComponent } from './core/core-navigation/core-navigation.component';
import { TutorialWidgetComponent } from './tutorial/tutorial-widget/tutorial-widget.component';
import { MediaLibraryModalComponent } from './media/media-library-modal/media-library-modal.component';
import { CoreDialogModalComponent } from './core/core-dialog-modal/core-dialog-modal.component';
import { Clipboard } from '@angular/cdk/clipboard';

declare const window: any;
declare const Mangler: any;
declare const $: any;
declare const $zoho: any;

@Injectable()
export class AppService {

	private themes = {
		standard: {
			id: 'standard',
			assetSuffix: '',
			styleSheet: null
		},
		pro: {
			id: 'pro',
			assetSuffix: '-pro',
			styleSheet: null
		}
	};

	activeTheme = this.themes.standard;

	notifications: NotificationsComponent = null;
	navigation: CoreNavigationComponent = null;
	tutorial: TutorialWidgetComponent = null;
	dialog: CoreDialogModalComponent = null;

	mediaLibrary: MediaLibraryModalComponent = null;
	onMediaLibrarySelect: EventEmitter<any> = new EventEmitter();

	countries = [];

	private _org = null;
	orgInfo = null;
	userImage = '';
	alertCount = 0;
	translatedVATTerm = 'VAT';

	calendarDateFormat = 'dd/mm/yy';

	shouldSkipDeviceCheck = false;

	path = '';

	introductionRouteId;
	blockQuotaLimitUsers = false;
	search = {
		catalogueSubscriptions: '',
		categoryList: '',
		customerList: '',
		entityList: '',
		labourList: '',
		productList: '',
		productCategoryList: '',
		productCounterList: '',
		quoteList: '',
		projectList: '',
		invoiceList: '',
		subscriptionList: '',
		catalogueList: '',
		poList: '',
		introPageList: '',
		pickList: '',
		transactionList: '',
		taskList: '',
		workOrderList: '',
		reportsList: ''
	};

	tabs = {
		catalogue: []
	};

	sort = {
		quoteList: '!modified_datetime',
		projectList: '!modified_datetime',
		invoiceList: '!bill_date',
		poList: '!order_date',
		introPageList: '!modified_datetime',
		workOrderList: '!end_datetime',
		taskList: '!id',
		billableTaskList: '!id',
		reportsList: '!id'
	};

	filter = {
		showArchivedEntities: false
	};

	tutorialData = {
		catalogueId: 28
	};

	// Available toolbar items: selectAll, undo, redo, bold, italic, blockQuote, ckfinder, imageTextAlternative, imageUpload, heading, imageStyle:full, imageStyle:side, indent, outdent, link, numberedList, bulletedList, mediaEmbed, insertTable, tableColumn, tableRow, mergeTableCells

	tinyContent = [
		'/print/css/bootstrap.min.css',
		'/print/css/wequote-icons.css?rev=37',
		'/print/css/styles.css?rev=5',
		'/print/print_quote_template.css?rev=3',
		'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700;1,400;1,700&display=swap',
		'/print/css/tinymce.css'
	];

	tinyContentFullWidth = [
		'/print/css/bootstrap.min.css',
		'/print/css/wequote-icons.css?rev=37',
		'/print/css/styles.css?rev=5',
		'/print/print_quote_template.css?rev=3',
		'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700;1,400;1,700&display=swap',
		'/print/css/tinymce-full-width.css'
	];

	tinyInit = {
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
			'autoresize pagebreak'
		],
		toolbar: 'undo redo | formatselect | bold italic underline | forecolor backcolor removeformat | link image table pagebreak | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code',
		base_url: '/tinymce',
		suffix: '.min',

		menubar: false, // True to show menu above editor, or text list of menus enabled
		autoresize_bottom_margin: 0,
		min_height: 200,
		max_height: 500,
		statusbar: false,
		placeholder: 'Content',
		toolbar_sticky: true,

		content_css: this.tinyContent,

		image_advtab: true,
		image_description: false,

		relative_urls: false,
		convert_urls: false,
		remove_script_host: false,

		browser_spellcheck: true,

		pagebreak_separator: '<div style="page-break-before: always;" class="page-break-section"></div>',
		pagebreak_split_block: true,

		extended_valid_elements: 'i[id|class|style],em,iframe[src|frameborder|style|scrolling|class|width|height|name|align|allow]',

		file_picker_callback: (callback, value, meta) => {
			if (meta.filetype === 'image') {
				if (this.mediaLibrary) {
					this.zone.run(() => {
						$('.tox-dialog-wrap').hide();
						$('.tox-tinymce-aux').hide();

						const sub = this.onMediaLibrarySelect.subscribe(data => {
							sub.unsubscribe();
							$('.tox-dialog-wrap').show();
							$('.tox-tinymce-aux').show();
							if (data) callback(data.url);
						});

						this.mediaLibrary.open({});
					});
				}
			}
		}
	};

	quoteHeaderUpdateTrigger = 0;

	get org() { return this._org; }
	set org(value) {
		if (this._org !== value) {
			this.tabs.catalogue = [];
			this._org = value;
			if (!value) {
				this.orgInfo = null;
				this.refreshTitle();
			}
		}
	}

	private _userName = '';
	private _userEmail = '';
	private _userId = null;

	get userName() { return this._userName; }
	set userName(value) {
		this._userName = value;
		try {
			$zoho.salesiq.visitor.name(value);
		} catch (e) { }
	}

	get userId() { return this._userId; }
	set userId(value) {
		this._userId = value;
	}

	get userEmail() { return this._userEmail; }
	set userEmail(value) {
		this._userEmail = value;
		try {
			$zoho.salesiq.visitor.email(value);
		} catch (e) { }
	}

	private _routeData: any = {};

	get routeData() { return this._routeData; }
	set routeData(value) {
		this._routeData = value;
	}

	public _routeParams: any = {};

	get routeParams() { return this._routeParams; }
	set routeParams(value) {
		let quoteChange = false;
		let projectChange = false;

		if (value?.quote !== this._routeParams?.quote) quoteChange = true;
		if (value?.project !== this._routeParams?.project) projectChange = true;

		this._routeParams = value;

		if (quoteChange) this.quoteNavigationChange.emit(value?.quote);
		if (projectChange) this.projectNavigationChange.emit(value?.project);
	}

	private _quoteStageChange: EventEmitter<string> = new EventEmitter();
	get quoteStageChange() { return this._quoteStageChange; }

	private _projectStatusChange: EventEmitter<string> = new EventEmitter();
	get projectStatusChange() { return this._projectStatusChange; }

	private _quotePriceChange: EventEmitter<any> = new EventEmitter();
	get quotePriceChange() { return this._quotePriceChange; }

	private _quoteNavigationChange: EventEmitter<any> = new EventEmitter();
	get quoteNavigationChange() { return this._quoteNavigationChange; }

	private _projectNavigationChange: EventEmitter<any> = new EventEmitter();
	get projectNavigationChange() { return this._projectNavigationChange; }

	private _globalSearchAdd: EventEmitter<any> = new EventEmitter();
	get globalSearchAdd() { return this._globalSearchAdd; }

	private _originalTitle;

	public onTutorialStepChange = new EventEmitter<number>();

	private _buildVersion = null;
	get buildVersion() { return this._buildVersion; }
	set buildVersion(value) {
		if (value !== this._buildVersion) {
			const oldBuildVersion = this._buildVersion;
			this._buildVersion = value;
			if (oldBuildVersion !== null) this.onBuildVersionChange.emit(this._buildVersion);
		}
	}

	public onBuildVersionChange = new EventEmitter<string>();

	constructor(
		private location: Location,
		private titleService: Title,
		private zone: NgZone,
		private clipboard: Clipboard
	) {
		this._originalTitle = titleService.getTitle();

		// Initialise themes
		const themeList = this.themes;
		$('link[href^="theme-"]').each(function () {
			const linkTitle = $(this).attr('title');
			if (linkTitle && themeList[linkTitle]) {
				themeList[linkTitle].styleSheet = this;
			}
		});
	}

	public ckReady(editor) {
		editor.ui.getEditableElement().parentElement.insertBefore(
			editor.ui.view.toolbar.element,
			editor.ui.getEditableElement()
		);
	}

	redirect(url) {
		window.top.location.href = url;
	}

	back() {
		this.location.back();
	}

	resetSearch() {
		Mangler.each(this.search, k => { this.search[k] = ''; });
	}

	setTitle(title) {
		const newTitle = this._originalTitle + (title ? ' - ' + title : '');
		this.titleService.setTitle(newTitle);
	}

	refreshTitle() {
		let title = this.routeData.title;
		if (title === ':org') title = this.orgInfo ? this.orgInfo.company_name : '';
		this.setTitle(title);
	}

	getLocalFlag(name: string) {
		let data = null;
		try { data = JSON.parse(localStorage.getItem('localFlags')); } catch (ex) { }
		if (!data) return false;
		return !!data[name];
	}

	setLocalFlag(name: string, value: boolean) {
		let data = null;
		try { data = JSON.parse(localStorage.getItem('localFlags')); } catch (ex) { }
		if (!data) data = {};
		data[name] = !!value;
		try { localStorage.setItem('localFlags', JSON.stringify(data)); } catch (ex) { }
	}

	addDefaultLocalOptions(options) {
		// Default options
		return Mangler.merge({
			scheduleView: 'w'
		}, options);
	}

	getLocalOption(name: string) {
		let data = null;
		try { data = JSON.parse(localStorage.getItem('localOptions')); } catch (ex) { }
		if (!data) data = {};
		return this.addDefaultLocalOptions(data)[name];
	}

	setLocalOption(name: string, value: any) {
		let data = null;
		try { data = JSON.parse(localStorage.getItem('localOptions')); } catch (ex) { }
		if (!data) data = {};
		data = this.addDefaultLocalOptions(data);
		data[name] = value;
		try { localStorage.setItem('localOptions', JSON.stringify(data)); } catch (ex) { }
	}

	copyToClipboard(text: string): boolean {
		return this.clipboard.copy(text);
	}

	compareAddress(a, b) {
		if (!a || !b) return false;

		const fields = [
			'address_line_1',
			'address_line_2',
			'address_line_3',
			'posttown',
			'county',
			'postcode',
			'country'
		];

		for (const field of fields) {
			if (
				('' + (a[field] || '')).trim()
				!==
				('' + (b[field] || '')).trim()
			) return false;
		}

		return true;
	}

	hasAddress(a) {
		if (!a) return false;

		const fields = [
			'address_line_1',
			'address_line_2',
			'address_line_3',
			'posttown',
			'county',
			'postcode',
			'country'
		];

		for (const field of fields) {
			if (('' + (a[field] || '')).trim() !== '') return true;
		}

		return false;
	}

	getIconClasses() {
		const result = [];

		for (let i = 0; i < document.styleSheets.length; i++) {
			const sheet = document.styleSheets[i];
			if (sheet.href && sheet.href.includes('/wequote-icons.css')) {
				for (let j = 0; j < sheet.cssRules.length; j++) {
					const rule = sheet.cssRules[j];
					if (rule instanceof CSSStyleRule && rule.selectorText) {
						const match = rule.selectorText.match(/\.(wq-.*)::/);
						if (match) result.push({
							id: match[1],
							name: match[1].replace(/^wq-/, '').replace(/-/g, ' ').replace(/(^[a-z]| [a-z]|-[a-z])/g, s => s.toUpperCase())
						});
					}
				}
			}
		}

		return result;
	}

	switchTheme(themeName) {
		if (!themeName) return;

		const theme = this.themes[themeName];
		if (!theme?.styleSheet || this.activeTheme === theme) return;

		// Find and enable new theme
		this.activeTheme = theme;
		$(theme.styleSheet).prop('disabled', false);

		// If found, disable the other themes
		// Using setTimeout will reduce flickering
		setTimeout(() => {
			Mangler.each(this.themes, (k, v) => {
				if (v !== theme) $(v.styleSheet).prop('disabled', true);
			});
		}, 0);
	}

}
