import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

import { ErrorComponent } from './error.component';
import { MobileBlockerComponent } from './mobile-blocker/mobile-blocker.component';

const routes: Routes = [
	{ path: 'auth', data: { module: 'auth' }, loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule) },
	{ path: 'signup', data: { module: 'signup' }, loadChildren: () => import('app/signup/signup.module').then(m => m.SignupModule) },
	{ path: 'developer', data: { module: 'developer' }, loadChildren: () => import('app/developer/developer.module').then(m => m.DeveloperModule) },

	{ path: 'proposal', data: { module: 'proposal' }, loadChildren: () => import('app/proposal/proposal.module').then(m => m.ProposalModule) },
	{ path: 'change-order', data: { module: 'change-order' }, loadChildren: () => import('app/change-order/change-order.module').then(m => m.ChangeOrderModule) },
	{ path: 'invoice', data: { module: 'invoice' }, loadChildren: () => import('app/invoice/invoice.module').then(m => m.InvoiceModule) },
	{ path: 'mobile', data: { module: 'core' }, component: MobileBlockerComponent },
	{ path: ':org/alert', data: { module: 'alert', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/alert/alert.module').then(m => m.AlertModule) },
	{ path: ':org/account', data: { module: 'account', authGuardSkipExpired: true, title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/account/account.module').then(m => m.AccountModule) },
	{ path: ':org/catalogue', data: { module: 'catalogue', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/catalogue/catalogue.module').then(m => m.CatalogueModule) },
	{ path: ':org/configure', data: { module: 'configure', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/configure/configure.module').then(m => m.ConfigureModule) },
	{ path: ':org/customer', data: { module: 'customer', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/customer/customer.module').then(m => m.CustomerModule) },
	{ path: ':org/overview', data: { module: 'overview', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/overview/overview.module').then(m => m.OverviewModule) },
	{ path: ':org/product', data: { module: 'product', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/product/product.module').then(m => m.ProductModule) },
	{ path: ':org/quote', data: { module: 'quote', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/quote/quote.module').then(m => m.QuoteModule) },
	{ path: ':org/sales-order', data: { module: 'sales-order', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/quote/quote.module').then(m => m.QuoteModule) },
	{ path: ':org/project', data: { module: 'project', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/project/project.module').then(m => m.ProjectModule) },
	{ path: ':org/settings', data: { module: 'settings', navigation: 'settings', title: ':org', billing: true }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/settings/settings.module').then(m => m.SettingsModule) },
	{ path: ':org/stock', data: { module: 'stock', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/stock/stock.module').then(m => m.StockModule) },
	{ path: ':org/template', data: { module: 'template', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/template/template.module').then(m => m.TemplateModule) },
	{ path: ':org/system', data: { module: 'system', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/system/system.module').then(m => m.SystemModule) },
	{ path: ':org/sales', data: { module: 'sales', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/sales/sales.module').then(m => m.SalesModule) },
	{ path: ':org/onboarding', data: { module: 'onboarding', title: ':org', onboarding: true }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule) },
	// Work Hub
	{ path: ':org/team', data: { module: 'team', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./team/team.module').then(m => m.TeamModule) },
	{ path: ':org/task', data: { module: 'task', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./task/task.module').then(m => m.TaskModule) },
	{ path: ':org/schedule', data: { module: 'schedule', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule) },
	{ path: ':org/work-order', data: { module: 'work-order', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./work-order/work-order.module').then(m => m.WorkOrderModule) },
	{ path: ':org/reports', data: { module: 'reports', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
	{ path: ':org/snaggs', data: { module: 'snaggs', navigation: 'main', title: ':org' }, canActivate: [AuthGuardService], canLoad: [AuthGuardService], loadChildren: () => import('./snaggs/snaggs.module').then(m => m.SnaggsModule) },

	{ path: ':org', redirectTo: ':org/overview', pathMatch: 'full' },
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '**', component: ErrorComponent, data: { module: 'core', auth: false, navigation: 'main' } },

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
